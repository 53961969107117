.sidebar-WTP {
  width: 300px;
  /* Adjust width as needed */
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-WTP h2 {
  margin: 0;
  margin-top: 5px;
}

.sidebar-WTP nav ul {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  margin-top: 15px;
}

.sidebar-WTP nav ul li {
  margin: 0px;
}

.sidebar-WTP nav ul li a {
  text-decoration: none;
  color: #333;
  display: flex;
  padding: 10px;
  align-items: center;
  font-size: 16px;
  padding-right: 0px;
  /* Adjust font size as needed */
  font-weight: 600;
}

.sidebar-WTP nav ul li a:hover {
  background-color: #cdcccc;
  /* padding: 10px; */
  /* width: 100%; */
}

.sidebar-WTP nav ul li a .sidebar-WTP-icon {
  margin-right: 15px;
  /* Space between icon and text */
  font-size: 20px;
  /* Adjust icon size as needed */
}

.sidebar-WTP nav ul li a.active {
  font-weight: bold;
  color: #007bff;
  /* Highlight color for active link */
}

.sidebar-WTP nav ul li a.active .sidebar-WTP-icon {
  color: #007bff;
  /* Highlight color for active icon */
}


/************************************************************/

.Flex-logo-sidebar {
  display: flex;
}

.Logo-Img-size {
  width: 40px;
  height: 40px;
}

.inbox-with-point {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.sidebar-WTP-MOBILE {
  display: none;
}


/* --------------------------media query------------------------------------ */
@media screen and (max-width:640px) {

  .sidebar-WTP {
    display: none;
  }

  /* .sidebar-WTP-MOBILE {
    display: block;
    width: 220px;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 0;
    z-index: 1000;
    top: 0;
  } */

  .sidebar-WTP-MOBILE {
    display: block;
    width: 265px;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    /* right: 0px; Start off-screen */
    top: 0;
    z-index: 1000000;
    transition: right 0.3s ease; /* Smooth sliding transition */
  }

  .sidebar-WTP-MOBILE h2 {
    margin: 0;
    margin-top: 5px;
  }

  .sidebar-WTP-MOBILE nav ul {
    list-style-type: none;
    padding: 0px;
    margin: 0;
    margin-top: 15px;
  }

  .sidebar-WTP-MOBILE nav ul li {
    margin: 0px;
  }

  .sidebar-WTP-MOBILE nav ul li a {
    text-decoration: none;
    color: #333;
    display: flex;
    padding: 10px;
    align-items: center;
    font-size: 18px;
    padding-right: 0px;
    /* Adjust font size as needed */
    font-weight: 600;
  }

  .sidebar-WTP-MOBILE nav ul li a:hover {
    background-color: #cdcccc;
    /* padding: 10px; */
    /* width: 100%; */
  }

  .sidebar-WTP-MOBILE nav ul li a .sidebar-WTP-icon {
    margin-right: 15px;
    /* Space between icon and text */
    font-size: 20px;
    /* Adjust icon size as needed */
  }

  .sidebar-WTP-MOBILE nav ul li a.active {
    font-weight: bold;
    color: #007bff;
    /* Highlight color for active link */
  }

  .sidebar-WTP-MOBILE nav ul li a.active .sidebar-WTP-icon {
    color: #007bff;
    /* Highlight color for active icon */
  }
}