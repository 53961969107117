
/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it sits on top of other content */
}

/* Modal content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  animation: slide-down 0.3s ease-out;
}

/* Title */
.modal-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

/* Input fields */
.modal-content input[type="text"],
.modal-content input[type="file"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Buttons */
.modal-content button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content button:first-of-type {
  background-color: #4caf50;
  color: #fff;
}

.modal-content button:first-of-type:hover {
  background-color: #0056b3;
}

.modal-content button:last-of-type {
  background-color: #f44336;
  color: #fff;
}

.modal-content button:last-of-type:hover {
  background-color: #d32f2f;
}

/* Animation */
@keyframes slide-down {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
