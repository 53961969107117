.container_wa-automation {
    background-color: white !important;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.spaceEvenly-automation {
    display: flex;
    justify-content: space-evenly;
}

.flexStart-automation {
    display: flex;
    justify-content: flex-start;
}

.alignContent-automation {
    display: grid;
    align-items: flex-start;
    align-items: baseline;
}

.flexEnd-automation{
    display: flex;
    justify-content: flex-end;
}

.iconStyle-automation{
    color: "black";
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.spaceBetween-automation {
    display: flex;
    justify-content: space-between;
}

.createBtn-automation {
    width: 80px;
    height: 30px;
    padding: 0px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: green;
    color: white;
    border: 1px solid white
}

.alignLeftContent-automation {
    display: grid;
    align-content: flex-start;
    padding: 20px;
}

.table_wa-automation {
    width: 100%;
    border-collapse: collapse;
}



.table_div-automation {
    height: 325px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.table_div-automation::-webkit-scrollbar {
    display: none;
}



.toggle-switch-automation {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 12px;
    top: -21px;
}

.toggle-switch-checkbox-automation {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch-label-automation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background: #ccc;
    border-radius: 34px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.toggle-switch-inner-automation {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 34px;
    transition: background-color 0.2s;
}

.toggle-switch-switch-automation {
    display: block;
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 50%;
    position: absolute;

    transition: transform 0.2s;
}

.toggle-switch-checkbox-automation:checked+.toggle-switch-label-automation {
    background: #199519;
}

.toggle-switch-checkbox-automation:checked+.toggle-switch-label-automation .toggle-switch-switch-automation {
    transform: translateX(22px);
}

.inputBox-automation {
    margin: 5px 0px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    width: 50%;
}

.redBtn-automation {
    background-color: #ff4163;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

.redBtn-automation:hover {
    background-color: #a42b40;
    color: white;
}

.greenBtn-automation {
    background-color: #2a9f79;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

.greenBtn-automation:hover {
    background-color: #1f7559;
    color: white;
}

.keyword-input-automation {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.keyword-list-automation {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.keyword-item-automation {
    background-color: #7090ff29;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    display: flex;
    align-items: center;
}

.delete-button_input-automation {
    cursor: pointer;
    margin-left: 8px;
    color: black;
    background-color: transparent;
    font-weight: 600;
    border: none;
}

.mainContainer-automation {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.left-container-automation,
.right-container-automation {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

.left-container-automation {
    background-color: #f0f8ff;
    width: 60%;
}

.right-container-automation {
    background-color: #faebd7;
    width: 40%;
}