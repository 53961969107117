.sidebar-container {
  font-family: Arial, Helvetica, sans-serif;
  /* position: fixed; */
  /* margin: 0 auto; */
}

.text-center {
  text-align: center;
}

.sidebar-container h1 {
  color: black;
  text-align: center;
  font-size: 22px;
}
.list {
  display: flex;
  justify-content: flex-start;
}

.list ul {
  list-style: none;
}

.list ul li {
  display: flex;

  align-items: center;
  gap: 10px;
  margin-top: 20px;
  list-style: none;
}
.list li:hover {
  /* background-color: #ffffff;  */
  cursor: pointer;
  transform: scale(1.01);
}

.menu {
  display: flex;
  align-items: center;
  margin-top: 12px;
  gap: 5px;
}

.Side-Title-Name{
  font-size: 15px;
}
