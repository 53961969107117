.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}


.backside {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 9;
    background-color: #0000009f;
}

.demo {
    background-color: rgb(179, 177, 177);
}

.main-form {
    width: 60%;
    height: 65%;
    display: flex;
    align-items: start;
    justify-content: start;
    position: fixed;
    z-index: 10;
    background-color: white;
    /* padding: 20px; */
    gap: 20px;
}


.form-left-side {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #0000003b; */
}


.selected-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
        padding: 10px 15px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #cfcbcb;

    }


}

.form-right-side {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;


}

.div-for-form {
    width: 100%;
    min-height: 25%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    align-items: center;
    justify-content: center;
    align-items: start;
    padding: 10px;

    label {
        font-size: 17px;
        font-weight: 650;
    }

    textarea {
        max-width: 100%;
        max-height: 100px;
        min-width: 100%;
        min-height: 50px;
        outline: none;
        border-radius: .5rem;

    }
}


.button-div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
    gap: 10px;

    button {
        padding: 5px 10px;
        font-size: 18px;
        background-color: rgb(15 203 6 / 79%);
        border: none;
        border-radius: .5rem;
        color: white;
        cursor: pointer;
        outline: none;
    }

    .cancel {
        background-color: #cfcbcb;
    }
}