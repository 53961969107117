@media (max-width: 750px) {
  .sidebar-wtp {
    width: 100%;
    background-color: white;
    border: none;
  }
  .chat-container {
    display: none;
  }

  .sidebar-wtp.hidden {
    display: none;
  }

  .chat-container {
    width: 100%;
  }

  .chat-container.show {
    display: block;
  }

  .flex-footer {
    width: 96%;
  }

  .chat__headerBackButton {
    display: block;
    margin-right: 0px;
  }

  .chat__body {
    padding: 15px;
  }

  .chat__header {
    padding: 7.5px 0px;
  }

  .chat__footer_mobile{
    display: block;
    position: fixed;
    display: flex;
    bottom: 0px;
    z-index: 1000;
    width: 95%;
 }

 .footer-icon_mobile span {
    font-size: 9px;
    font-weight: 500;
 }

 .Icon-Size-wtp{
    font-size: 25px;
 } 

 .sidebar__search{
    border-radius: 30px;
    background-color: #f3f3f3;;
    border-bottom: none;
    width: 90%;
    margin: auto;

 }

 .sidebar__search input{
    background-color: #f3f3f3;
    font-size: 15px;
 }

 .Icon-search-wtp{
    font-size: 20px;
    color: grey;
    margin: 0px 5px;
}

.sidebar__header {
    background-color: white;
    border-bottom: none;

 }

.Icon-margin-send {
    font-size: 25px;
}

.chat__footer input{
    font-size: 15px;
}

.chat{
    box-shadow: none;
}
.chat__headerInfo p{
    font-size: 11px;
}

}
  


