.General-Setting-Head {
  font-size: 22px;
  font-weight: 600;
}
.General-form-content {
  background-color: rgb(255 255 255);
  border: 1px solid #ececec;
  width: 90%;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: max-content;
  /* overflow-y: scroll; */
  scrollbar-width: thin;
}
.Flex-Input_General {
  display: flex;
  justify-content: space-between;
}

.Input_Box-General {
  width: 24%;
  margin-bottom: 15px;
}
.inputName-lbl {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}
.Input-Fill-Box {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.Menu-bg-color {
  background-color: pink;
}

.rotate-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.rotate-button i {
  font-size: 20px; /* Adjust size as needed */
  transition: transform 0.3s ease; /* Smooth transition for rotation */
}

.rotate-button:hover i {
  transform: rotate(90deg); /* Rotate 90 degrees on hover */
}

.color-picker-container {
  display: flex;
  align-items: center;
}

.color-box {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-left: 10px;
}

.Images-Height-General {
  height: 30px;
  padding: 4px;
}

.refresh-button {
  background-color: #2623ca; /* Background color of the button */
  border: none; /* Remove default button border */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 8px; /* Adjust padding as needed */
}

.refresh-button:hover {
  background-color: #9c9a9a; /* Change background color on hover */
}

.refresh-button i {
  margin-right: 5px; /* Adjust icon spacing */
}

/****************************Product Category Master********************************/

.Category-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Image-Product-Cate {
  display: flex;
  width: 70px;
}

.Add-button {
  padding: 5px 5px;
  background-color: #00d659b9;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/***************************Modal Css*******************************/

.modal-Category {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content-contact {
  padding: 0 !important;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

.modal-title-contact {
  padding: 10px 20px !important;
}

.modal-title h3 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.close {
  color: #e90c0c;
  font-size: 30px;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
}

/* ModalAddCategory styles */
.modal-add-category {
  margin-top: 20px;
}

/* Example of ModalAddCategory component CSS */
.modal-add-category input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-add-category button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-add-category button:hover {
  background-color: #0056b3;
}

.modal-heading-top {
  font-weight: 600;
}

.Header-Category-Content {
  display: flex;
  width: 100%;
}

.Category-Left-Width {
  width: 78%;
}

.Category-Right-width {
  width: 28%;
}

/*************************************User Login****************************************/

.Login-container {
  position: relative;
  top: 100px;
  max-width: 350px;
  background: #f8f9fd;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(244, 247, 251) 100%
  );
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: rgb(16, 137, 211);
}

.form {
  margin-top: 20px;
}

.form .input-Box-login {
  width: 100%;
  background: white;
  border: none;
  height: 43px;
  /* padding: 15px 20px; */
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
  text-align: center;
}

.form .input-Box-login::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input-Box-login::placeholder {
  color: rgb(170, 170, 170);
}

.form .input-Box-login:focus {
  outline: none;
  border-inline: 2px solid #12b1d1;
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: #0099ff;
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  font-weight: bold;
  background: linear-gradient(
    45deg,
    rgb(16, 137, 211) 0%,
    rgb(18, 177, 209) 100%
  );
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 9px;
}

.Flex-Login-User {
  display: flex;
  justify-content: center;
}

.password-toggle-icon {
  position: absolute;
  right: 51px; /* Adjust as needed */
  top: 55%; /* Position vertically centered */
  transform: translateY(-50%);
  cursor: pointer;
  color: #666; /* Icon color */
  font-size: 18px; /* Adjust size as needed */
}

.password-toggle-icon:hover {
  color: #333; /* Darker color on hover */
}

.input-Box-login.text {
  /* Styles for visible password */
  width: 100%; /* Adjust width as needed */
}

.input-Box-login.password {
  /* Styles for hidden password */
  width: 100%; /* Adjust width as needed */
}

/***********************************WhatsApp Panel******************************************/

/* Form container styling */
/* Form container styling */
.my-form {
  /* width: 964px; */
  margin: auto;
  max-height: 620px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: scroll;
  scrollbar-width: none;
  padding-bottom: 0px !important;
}

/* Style for form groups */
.my-form__group {
  margin-bottom: -7px;
  /* width: 100%; */
}

/* Flex container for side-by-side fields */
.my-form__flex-group {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px; /* Add spacing between fields */
}

/* Style for labels */
.my-form__label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

/* Style for text and number inputs */
.my-form__input-text,
.my-form__input-number {
  width: calc(50% - 10px); /* Adjust width for side-by-side alignment */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

/* Ensure labels and inputs have consistent margins */
.my-form__input-text,
.my-form__input-number {
  margin-bottom: 10px;
  width: 100%;
}

/* Style for checkbox inputs */
.my-form__checkbox {
  margin-right: 10px;
}

/* Style for submit button */
.my-form__button-container {
  display: flex;
  /* padding-bottom: 10px ; */
  background-color: #f9f9f9;
  justify-content: center; /* Center horizontally */
  /* margin-top: 20px; Add some space above the button */
}

.my-form__submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 50px;
  font-size: 16px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.my-form__submit-btn:hover {
  background-color: #0056b3;
}

.my-form__dropdown {
  width: 100%;
  height: 40px;
}
/* -----------------------------------change --------------------------------- */
/* Container for the select element */
.select-container {
  position: relative;
  width: 250px; /* Set width according to your layout */
  margin: 20px 0; /* Add some spacing */
}
select {
  width: 100%;
  padding: 10px 15px; /* Add padding for a better touch target */
  border: 1.5px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background */
  font-size: 16px; /* Font size */
  color: #333; /* Text color */
  appearance: none; /* Remove default arrow */
  outline: none; /* Remove outline */
  transition: border-color 0.3s; /* Smooth transition for border */
}
.select-container::after {
  content: "▼"; /* Custom arrow */
  position: absolute;
  right: 15px; /* Position it inside the select */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent clicks on the arrow */
  color: #333; /* Arrow color */
}