.Slider-Table {
  /* background: linear-gradient(to right, #fceed2, #faf2e9); */
  background-color: rgb(218, 217, 217);
  width: 90%;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  position: relative;
}

.image-cell-slider {
  display: flex;
  overflow-x: auto;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  scrollbar-width: thin;
  scroll-behavior: smooth;
  width: 650px;
}

.image-parent-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container-slider {
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}
