.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
}

.logo {
  font-size: 1.5rem;
}

.nav-links {
  list-style-type: none;
  display: flex;
}

.nav-links li {
  margin-right: 10px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
}

.nav-links a:hover {
  background-color: #555;
}

.logout-button {
  background-color: #e63946;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.logout-button:hover {
  background-color: #f18c99;
}



.progress-bar-container {

  height: 20px;
  width: 200px;
  right: 100px;
  background-color: #e0e0df;
  border-radius: 50px;
  box-shadow: 0 3px 3px -5px #000000, 0 2px 5px #0000001f;
  margin: 20px 0;
  overflow: hidden;
  position: static;
}

/* The inner filler bar that shows progress */
.progress-bar-filler {
  height: 100%;
  width: 0%;
  background: linear-gradient(90deg, #fff 0%, green 100%);
  border-radius: inherit;
  text-align: center;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
}

/* Label that shows the percentage inside the bar */
.progress-bar-label {
  padding: 5px;
  color: black;
  font-size: 16px;
}


.container-progressbar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 100px;
  flex-direction: row;
  gap: 10px;
}

.all-contact {
  position: absolute;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #00d47e;
  cursor: pointer;
}