.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }

  .news-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    animation: slide-down 0.3s ease-out;
  }

  .news-modal-content-contact {
    padding: 0 !important;
  }
  .news-modal-content div {
    margin-top: 0;
    margin-bottom: 12px;
  }

  .news-modal-content button {
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 12px;
  }
  
  .news-modal-content button:first-of-type {
    background-color: #4caf50;
    color: #fff;
  }
  
  .news-modal-content button:first-of-type:hover {
    background-color: #0056b3;
  }
  
  .news-modal-content button:last-of-type {
    background-color: #f44336;
    color: #fff;
  }
  
  .news-modal-content button:last-of-type:hover {
    background-color: #d32f2f;
  }