/* body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
} */

.App {
  display: flex;
  height: 100vh;
}

.heading {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}
.sidebar {
  background-color: rgb(248 248 248);;
  max-width: 20%;
  height: 100vh;
  min-width: 21%;
  overflow-y: auto;
  /* height: 100vh; */
  /* position: fixed; */
}

.body-content {
  flex-grow: 1;
  height: "100vh";
  overflow-y: "auto";
  background-color: "#ffffff";
  /* background-color: aqua; */
  padding: "20px";
}

.div-progress{
  width: 200px;
  height: 40px;
  background-color: red;
}
