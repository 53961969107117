@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.Flex-Head-Right-Compaigns {
  display: flex;
}

.Right-Campaigns-Body {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* position: fixed; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: white; */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 900;
}

.Campaigns_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6.5px 15px;
  /* border-bottom: 1px solid lightgray; */
  background-color: #f6f6f6;
}

.Campaigns_headerInfo {
  flex: 1;
}

.Campaigns_headerInfo h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.Campaigns_headerInfo p {
  margin: 0;
  color: gray;
  font-size: 13px;
}

.Campaigns_headerRight {
  display: flex;
  justify-content: flex-end;
}

.Campaigns_headerButton {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 40px;
  color: #2c602c;
}

.Campaigns_body_Bg {
  flex-grow: 1;
  height: "100vh";
  background-color: #f6f6f6;
  /* background-color: aqua; */
  padding: "20px";
  overflow: scroll;
}

.Campaigns-form-content {
  background-color: rgb(255 255 255);
  border: 1px solid #ececec;
  width: 92%;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 40px 20px;
  height: max-content;
  /* overflow-y: scroll; */
  scrollbar-width: thin;
}

.Table-Body-content {
  width: 100%;
}

.Table-Campaigns-color {
  background-color: white;
  color: black;
}

.th-name-camp {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 20px;
  text-align: left;
  color: white;
  background-color: rgb(46 73 140);
}

.td-name-campaigns {
  padding: 15px;
  border: 0;
  text-align: left;
  font-size: 15px;
}

.action-icon-container {
  position: relative;
}

.dropdown-menu-action {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 100%;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
  border-radius: 10px;
}

.dropdown-menu-action button {
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.dropdown-menu-action button:hover {
  background-color: #f0f0f0;
}

.Campaigns_headerRight {
  position: relative;
}

.dropdown-menu-Model {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 85%;
  right: 9px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
}

.dropdown-menu-Model button {
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.dropdown-menu-Model button:hover {
  background-color: #f0f0f0;
}

.heading-welcome-ur {
  font-size: 17px;
  font-weight: 500;
  color: black;
  border-bottom: 1px solid #d9d9d9;
  text-align: left;
}

.Table-Bg-color {
  background-color: rgb(255, 255, 255);
}

.Table-Bg-color:hover {
  background-color: #f5f5f5;
}

.view-all-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.view-all-container-contact{
  background-color: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  margin-top: 0 !important;
  
}

.view-all-button {
  background-color: #00d47e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.view-all-button:hover {
  /* background-color: #ffffff;
  color: #8f2e37;
  border: 1px solid #00d47e; */
}

.Create-Compaigns-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 25px;
}

/***********************Create Campaigns********************************/

.Content-padding-Campaigns {
  padding: 10px;
  background-color: #f9f9f9;
  /* border-bottom: 1px solid; */
  border-color: gray;
  /* margin-bottom: 12px; */
}

.Flex-Input-Campaigns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.Flex-Input-Campaigns input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-size: 16px;
  outline: none;
  width: 70%;
}

.Yes-BM-login-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: content-box !important;
}

/* .Flex-Input-Campaigns input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  } */

.Input-Box-Compaigns {
  width: auto;
}

.Campaigns-lbl {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}

.Flex-Area-Campaigns {
  display: flex;
  justify-content: space-between;
}

.Header-Area-width {
  width: 66%;
}

.Input-textarea-Box {
  width: 96%;
  height: 150px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d7d5d5;
  font-size: 15px;
  padding: 5px;
}

.Query-select-Box {
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-size: 16px;
  outline: none;
}

.Icon-flex-add {
  display: flex;
  gap: 25px;
  align-items: center;
}

.icon-position-camp {
  position: relative;
  top: 6px;
  font-size: 25px;
}

.Right-Area-Box {
  width: 35%;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  max-height: 305px;
  overflow: scroll;
}

.Right-Content-cam-text {
  font-size: 15px;
  border: 1px solid grey;
  outline: none;
  background-color: #d4e1e5;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px;
  margin-top: 2px;
}

.Right-Content-cam-text:hover {
  background-color: #bfe0cef5;
}

.modal-campaigns-width {
  width: 65%;
}

.Query-width-name {
  width: 50%;
}

.Query-width-name-contact {
  width: 100% !important;
}

.Text-Query-width {
  width: 100%;
}

.Mobile-textarea-input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-size: 16px;
  outline: none;
}

/***********************Conatacts ******************************/

.export-all-button {
  background-color: rgb(46 73 140);
  color: white;
  padding: 10px 20px;
  border: none;
  border: 1px solid #a0a3bd;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem ;
  display: flex;
  align-items: center;
}

.export-all-button:hover {
  background-color: #ffffff;
  color: black;
  border: 1px solid rgb(29 41 71);
}

.header-checkbox {
  margin: 0;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

/* .search-input-contact {
  padding-left: 50px !important;
} */

.Contact-Type-button {
  background-color: #c1c1c1;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  width: 100%;
  border: 1px solid #c1c1c1;
}

.Contact-Type-button:hover {
  background-color: #ffffff;
  color: black;
  border: 1px solid #a0a3bd;
}

.Dropdown-menu-Contact {
  display: block;
  position: absolute;
  top: 100%;
  /* Position below the button */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 20%;
  /* Adjust to fit button width */
  border-radius: 10px;
}

.Dropdown-item-contact {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
}

.Dropdown-item-contact:last-child {
  border-bottom: none;
}

.Dropdown-item-contact:hover {
  background-color: #f1f1f1;
}

.Content-position-contact {
  position: relative;
}

.contact-subscribed {
  text-align: center;
  font-weight: 500;
}

.Contacts-Groups-content {
  display: flex;
  justify-content: flex-start;
  width: 92%;
  margin: 20px auto;
  border-radius: 8px;
  padding: 20px;
}

/* .Groups-Contacts-width{
  width: 10%;
} */

.Groups-Contacts-container {
  display: flex;
  flex-direction: column;
}

.Groups-Contacts-width {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: border-bottom 0.3s;
}

.Groups-Contacts-width:hover {
  color: #08122e;
  /* Optional: color change on hover */
  /* border-bottom: 2px solid #f6f6f6; */
}

.Groups-Contacts-width.active {
  border-bottom: 2px solid #ff3000;
  font-weight: bold;
}

.ExistingGroup-select-Box {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  outline: none;
  margin-bottom: 10px;
  width: 98%;
  border-radius: 10px;
}

.Checkbox-table-row {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.Input-contact-box {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-dropdown-select {
  position: relative;
  display: inline-block;
  width: 95%;
  /* Adjust width as needed */
}

.dropdown-header-select {
  cursor: pointer;
  padding: 6px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.dropdown-list-select {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  top: 77%;
  left: 0;
  border-radius: 10px;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.dropdown-option input {
  margin-right: 10px;
}

.dropdown-option span {
  flex: 1;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

/************************WABA Status***********************************/

.waba-status-content {
  width: 95%;
  margin: 0px auto;
  font-size: 18px;
  font-weight: 500;
}

.clickhere-color {
  color: rgb(5, 5, 5);
}

.clickhere-color:hover {
  color: green;
}

.pagination-controls {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 10px;
}

.pagination-controls button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 0;
}

.pagination-controls span {
  line-height: 1;
}

.dlt-btn {
  border: none;
  width: 30px;
  height: 30px;
  /* z-index: 2; */
  background-color: rgb(46 73 140);

  span {
    color: #fff;
    font-size: 16px;
    width: 100%;

    display: block;
    text-align: center;
    transition: background-color 0.3s;
  }
}

.add-btn {
  border: none;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  /* z-index: 2; */
  background-color: white;
  border-radius: 5px;

  span {
    /* color: #fff; */
    font-size: 16px;
    width: 100%;
    border-radius: 10px;
    color: black;
    display: flex;
    text-align: center;
    transition: background-color 0.3s;
  }
}

.span-cross {
  /* color: grey; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.gruop-span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  width: auto !important;
}

.form-display {
  padding: 0.5rem;
  border: 1px solid #ddd;
  /* Optional: border for better visibility */
  border-radius: 4px;
  /* Optional: rounded corners */
  background-color: #f9f9f9;
  /* Optional: background color */
  color: #333;
  /* Text color */
  min-height: 2rem;
  /* Adjust height as needed */
  box-sizing: border-box;
  /* Include padding and border in element's total width and height */
  font-weight: 500;
}
.dropdown-menu-action-close {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
}

.view-member {
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  justify-content: start;
}

.modal-overlay-members {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-members {
  background: white;
  max-height: 80%;
  overflow: hidden;
  padding: 20px;
  border-radius: 5px;
  z-index: 2;
  width: 70%;
  max-width: 100%;
  position: relative;
}

.close-button-members {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: red;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-container {
  position: relative;
  width: 30px;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown.show {
  display: block;
}

.dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.toggle-btn {
  margin-top: 10px;
  /* Optional: styling for the toggle button */
}

.include-dlt-add {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu-action-close {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;

  background-color: transparent;
}

.Input-Box-Filter {
  width: 100px;
  cursor: pointer;
}

.search-input-contact {
  width: 100%;
  max-width: 400px;
  /* Adjust the max-width as needed */
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  /* Light grey border */
  border-radius: 5px;
  /* Rounded corners */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* Subtle shadow */
  outline: none;
  /* Remove default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition */
}

.search-input-contact::placeholder {
  color: #888;
  /* Light grey placeholder text */
  opacity: 1;
  /* Ensure placeholder is visible */
}

.Query-custom-date-start {
  width: 30px;
}

.date-filter-btn {
  width: auto;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.input-box-date {
  width: 100px !important;
  font-size: 15px !important;
}

.input-box-compaing {
  display: flex;
  align-items: start;
  justify-content: center;
}

.input-box-compaing {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.inside-custom-date {
  display: flex;
  gap: 5px;
}

.set-height {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.td-name-campaigns {
  border: 1px solid #ccc;
}

.close-members {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

.members-ul {
  overflow-y: scroll;
  height: 350px;
}

/* .Flex-Head-Right-Compaigns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
} */

.templates-container-meta-template {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 90vh;
}

.big-card-container-meta-template {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* max-height: 16%; */
}

.big-card-header-meta-template {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.big-card-body-meta-template {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  scrollbar-width: none;
}

.small-card-meta-template {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  position: relative; /* To position the arrow button */
  transition: all 0.3s ease;
}
.small-card-meta-template:hover {
  background-color: #fcfcfc;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.002);
}

.small-card-header-meta-template {
  font-size: 15px;
  margin-bottom: 10px;
}

.small-card-body-meta-template {
  font-size: 15px;
  margin-bottom: 10px;
}

.arrow-button-meta-template {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
}

.arrow-button-meta-template:hover {
  text-decoration: underline;
}

/* Modal Overlay */
.modal-overlay-meta-template {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content-meta-template {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: -webkit-fill-available;
  max-width: 600px;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.close-button-meta-template {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.template-details-meta-template {
  margin-top: 20px;
  height: 55vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.component-details-meta-template {
  margin-bottom: 10px;
}

/* Upload IMage  */

.upload-container-meta-template {
  margin-top: 12px;
}

.upload-label-meta-template {
  font-weight: bold;
  margin-bottom: 8px;
}

.upload-area-meta-template {
  border: 2px dashed #007bff; /* Dotted border color */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
}

.upload-area-meta-template:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light background on hover */
}

.upload-input-meta-template {
  display: none; /* Hide the default file input */
}

.upload-box-meta-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-icon-meta-template {
  margin-bottom: 8px; /* Space between icon and text */
}

.upload-text-meta-template {
  color: #6c757d; /* Secondary text color */
  font-size: 14px;
}

.image-preview-container-meta-template {
  margin-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview-meta-template {
  max-width: 100%;
  max-height: 200px;
  border: 1.5px solid #007bff;
  border-radius: 8px;
  object-fit: cover;
}

.remove-image-button-meta-template {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  /* padding: 8px 12px; */
  cursor: pointer;
  margin-top: 10px;
}

/* .remove-image-button-meta-template:hover {
  background-color: #ff1a1a; 
} */

.loading-spinner-meta-template {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* custom template css */
.whatsapp-template-form-custom-template {
  width: 100%;
  max-width: 1200px; /* Set a max width for the entire form */
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title-custom-template {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-preview-container-custom-template {
  /* display: flex;  */
  justify-content: space-between;
  height: 90vh;
  /* position: relative; */
  padding-bottom: 60px;
}

.form-container-custom-template {
  width: 70%;
  overflow-y: auto;
  scrollbar-width: none;
}

.form-group-custom-template {
  margin-bottom: 15px;
}

.form-label-custom-template {
  font-weight: bold;
  margin-bottom: 5px;
  width: max-content;
}

.form-select-custom-template {
  width: 99%;
  padding: 10px;
  border: 1px solid #ced4da;

  border-radius: 15px;
  /* border-top: 5px; */
  margin-top: 12px;
}

.form-input-custom-template,
.form-textarea-custom-template {
  width: 96%;
  padding: 10px;
  border: 1px solid #ced4da;

  border-radius: 15px;
  /* border-top: 5px; */
  margin-top: 12px;
}

.form-textarea-custom-template {
  height: 100px;
}

.submit-button-custom-template {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button-custom-template:hover {
  background-color: #0056b3;
}

.preview-custom-template {
  width: 40%;
  margin-left: 20px; /* Add some space between the form and preview */
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #ffffff;
}

.preview-title-custom-template {
  font-size: 18px;
  margin-bottom: 10px;
}

.preview-box-custom-template {
  border: 1px dashed #ced4da;
  padding: 10px;
  background-color: #f1f3f5;
}

.preview-text-custom-template {
  white-space: pre-line;
}

.unsubscribe-text-custom-template {
  font-size: 12px;
  color: #6c757d;
}

.media-selection-custom-template {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.media-option-custom-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px dashed #ccc;
  width: 100px;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  max-height: 103px;
}

.media-option-custom-template.selected {
  border-color: #28a745; /* Green color for selected option */
}

.media-option-custom-template p {
  margin-top: 5px;
  font-weight: bold;
}

.flex-row-custom-template {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
  gap: 10px;
}

.flex-row-custom-template label {
  flex: 1;
}

.flex-row-custom-template input,
.flex-row-custom-template select {
  /* width: 96%; */
  padding: 10px;
  border: 1px solid #ced4da;

  border-radius: 15px;
  /* border-top: 5px; */
  margin-top: 12px;
}

.quick-reply-input-custom-template {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.remove-quick-reply-input-custom-template {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
}

.add-reply-button-custom-template {
  background-color: rgb(248, 195, 96);
  border-style: none;
  /* display: flex;
  align-items: center; */
}

.Flex-Input-custom-templates {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: -webkit-fill-available;
}

.call-to-action-section {
  width: 95%;
}

.form-footer-custom-template {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  padding: 10px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-button-custom-template,
.cancel-button-custom-template {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button-custom-template {
  background-color: #4caf50;
  color: white;
}

.cancel-button-custom-template {
  background-color: #f44336;
  color: white;
}

.save-button-custom-template:hover {
  background-color: #45a049;
}

.cancel-button-custom-template:hover {
  background-color: #d32f2f;
}

.mobile-box-custom-template {
  background: url("../Assets/Images/mobile-bg.png");
  border-radius: 10px;
  height: 90%;
  overflow: hidden;
  max-width: 360px;
  margin: 10px auto;
  border: 1px solid #e1e1e1;
}

.mobile-header-custom-template {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
}

.mobile-notification-count-custom-template {
  color: #007aff;
  display: flex;
  align-items: center;
}

.pl-3-custom-template {
  padding-left: 1rem !important;
}

.mobile-avatar-custom-template {
  width: 36px;
  border-radius: 50%;
  border: 1px solid #eff0f6;
  padding: 2px;
}

.text-dark-custom-template {
  color: #14142b !important;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ml-3-custom-template {
  margin-left: 1rem !important;
}
.ml-auto-custom-template {
  margin-left: auto !important;
  display: flex;
  align-items: center;
}
.mobile-body-custom-template {
  /* min-height: 600px; */
  max-height: 438px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.mobile-time-custom-template {
  background: #dbdfeb;
  padding: 6px 40px;
  display: inline-block;
  margin: 10px auto;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.chat-reply-custom-template {
  width: 100%;
  padding: 15px;
}

.card-custom-template {
  border-radius: 16px;
  overflow: hidden;
  /* position: relative; */
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  max-width: 290px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-body-custom-template {
  /* -ms-flex: 1 1 auto; */
  font-family: "Poppins", sans-serif;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding: 0.25rem !important;
}

.card-pin-custom-template {
  position: absolute;
  left: -12px;
  top: -3px;
}

.text-muted-custom-template {
  font-size: 14px;
  color: #888ea8 !important;
}

.container-fluid-custom-template {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row-custom-template {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.preview-image-custom-template {
  margin-bottom: 0.5rem !important;
  width: 100% !important;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
  border-radius: 12px;
}

.preview-header-text-custom-template {
  font-size: 14px;
}

.add-variable-button-custom-template {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-variable-button-custom-template:hover {
  background-color: #0056b3;
}

.variable-example-custom-template {
  margin-top: 5px;
}
